@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSans'), url('./fonts/bnppSans.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSansBold'), url('./fonts/bnppSansBold.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSansBoldItalic'), url('./fonts/bnppSansBoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSansLight'), url('./fonts/bnppSansLight.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSansLightItalic'), url('./fonts/bnppSansLightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'BNPPSansCondV2';
  src: local('bnppSansCondV2'), url('./fonts/bnppSansCondV2.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'BNPPSansCondV2';
  src: local('bnppSansCondBoldV2'), url('./fonts/bnppSansCondBoldV2.ttf') format('truetype');
  font-weight: 700;;
}

@font-face {
  font-family: 'BNPPSansCondV2';
  src: local('bnppSansCondLightV2'), url('./fonts/bnppSansCondLightV2.ttf') format('truetype');
  font-weight: 300;;
}

@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSansExtraBold'), url('./fonts/bnppSansExtrabold.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'BNPPSans';
  src: local('bnppSansExtraItalic'), url('./fonts/bnppSansExtraboldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
